import { companyBucket } from "../../Utils/tpscanStyle"

const AlertPopup = (props) => {
    const { setPopup } = props

    return (
        <div className="h-full w-full flex justify-center items-center absolute top-0 bg-black/[0.3] backdrop-blur-sm z-10">
            <div className=" w-4/5 bg-white p-3 rounded" style={{ fontFamily: "Poppins" }}>
                <p className="text-center">Are you sure that you want to cancel your request?</p>
                <div className="flex p-3 mt-6">
                    {
                        companyBucket === "done" ?
                            <a
                                href="https://app.dentistry.one/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className=" text-center flex-grow bg-white text-sky-500 font-semibold py-2 border border-sky-500 rounded-full"   >
                                YES
                            </a> :
                            <button
                                className=" text-center flex-grow bg-white text-sky-500 font-semibold py-2 border border-sky-500 rounded-full"
                                onClick={() => { window.history.back() }}  >
                                YES
                            </button>
                    }
                    <button
                        type="button"
                        className="flex-grow bg-sky-500 text-white font-semibold ms-3 py-2 rounded-full"
                        onClick={() => { setPopup(false) }} >
                        NO
                    </button>

                </div>
            </div>
        </div>
    )
}

export default AlertPopup