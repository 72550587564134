import { dentalOfficeName } from "../../Utils/AppDetails/appDetails";
import { API_HOST } from "../BaseUrl";
import client, { headers } from "./config";

export const getAppsDetails = (appUrl) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.post( 
        API_HOST + `apps/details`,
        { appUrl },
        { headers }
      );
      if (response.status === 200) {
        resolve(response);
      }
      reject(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const postLogDetails = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.post(API_HOST + `log-updation`,{ data });
      if (response.status === 200) {
        resolve(response);
      }
      reject(response);
    } catch (error) {                                               
      reject(error);
    }
  });
};

export const getBinaryDataOfClinicImage = (imageName) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
    hosts: window.location.origin,
  };

  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.get(
        API_HOST + `binaryDataOfClinicImage/${imageName}`,
        {
          headers,
        }
      );
      if (response.status === 200) {
        resolve(response);
      }
      reject(response);
    } catch (error) {
      reject(error);
    }
  });
};



export const checkAppVersion = () => {
  const updatedHeaders = {
    ...headers,
    Authorization: localStorage.getItem("authToken"),
  };

  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.get(API_HOST + `update-hygiene-version`, {
        headers: updatedHeaders,
      });
      if (response.status === 200) {
        resolve(response);
      }
      reject(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const getTpScanAppDetails = () => { 
 headers.tpScanAppDetails=JSON.parse(localStorage.getItem("companyBucket")).companyBucket=="tl"?"toothlens":JSON.parse(localStorage.getItem("companyBucket")).companyBucket
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.get(
        API_HOST + `tpapp/details`,
        { headers}
      );
      if (response.status === 200) {
        resolve(response);
      }
      reject(response);
    } catch (error) {
      reject(error);
    }
  });
};