import { useState } from "react"
import { useSwipeable } from "react-swipeable";
import selfieGirl from "../../../assets/unilever/lady_guide.png"
import chin from "../../../assets/demoImages/chin.jpg"
import close from "../../../assets/Icons/close.png"
import rotateMobile from "../../../assets/video/rotateMobile.mp4"

const UpperJawDemonstartion = ({ gotIt, back }) => {
    const [currentstage, setCurrentstage] = useState(0)

    const data = [
        {
            isVideo: false,
            text: <p>Tundukkan kepala sedikit. Lalu putar ponsel dan arahkan sehingga kamera depan berada tepat di bawah dagu pada sudut 90 derajat</p>
        },
        {
            isVideo: true,
            text: <p>Tundukkan kepala sedikit. Lalu putar ponsel dan arahkan sehingga kamera depan berada tepat di bawah dagu pada sudut 90 derajat</p>
        }
        
    ]

    const handleSwipe = (x) => {
        if (x < 0 && currentstage > 0) {
            setCurrentstage((prevState) => prevState - 1);
        } else if (x > 0 && currentstage < data.length - 1) {
            setCurrentstage((prevState) => prevState + 1);
        }
    };

    const handlers = useSwipeable({
        onSwipedLeft: () => handleSwipe(1),
        onSwipedRight: () => handleSwipe(-1),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true,
    });

    const moveNext = () => {
        if (currentstage === 1) {
            back(false)
        } else {
            setCurrentstage(1)
        }
    }

    return (
        <div {...handlers} className="h-full flex flex-col">
            <div className="flex justify-end p-3 z-10">
                <button
                    type="button"
                    className=""
                    onClick={() => { back(false) }}>
                    <img src={close} alt="close" className="h-8" />
                </button>
            </div>
            {
                currentstage === 1 &&
                <div className="absolute -top-8 flex justify-center  w-full ">
                    <p className="absolute bottom-5 text-gray-400 font-medium text-base">Dagu</p>
                    <img src={chin} className="h-8" alt="chin" style={{ height: "170px" }} />
                </div>
            }
            <div className=" flex-grow flex items-end justify-center ">
                {
                    currentstage === 1 ?
                        <video
                            autoPlay
                            loop
                            muted
                            className=" w-full"

                        >
                            <source src={rotateMobile} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        : <img src={selfieGirl} alt="demo-girl" className="w-full" />
                }
            </div>

            <div className="px-4">
                <div className=" text-sm mb-6" style={{ color: "#323232" }}>
                    {data[currentstage].text}
                </div>
                <button
                    type="button"
                    className="w-full bg-red-unilever text-white text-base font-semibold py-3"
                    onClick={moveNext}
                >
                    {currentstage === 0 ? "berikutnya" : "Kembali"}
                </button>
                <ul className="flex justify-center">
                    {data.map((point, i) => {
                        return (
                            <span
                                key={i}
                                className={`${currentstage === i ? "text-black" : "text-gray-400"} text-3xl`} >
                                &#8226;
                            </span>
                        );
                    })}

                </ul>
            </div>
        </div>
    )
}

export default UpperJawDemonstartion




















