import { UAParser } from 'ua-parser-js';

const parser = new UAParser().getResult();

export const GetBrowserDetails = () => {
    return parser.browser;
};

export const GetDeviceDetails = () => {
    return parser.device;
};

export const GetOSDetails = () => {
    return parser.os;
};

export const GetFullDetails = () => {
    return parser
};

export const getDesktopOrMobile = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    const isMobile = /iPhone|Android/i.test(userAgent);
    const isAppleTablet = /iPad|Macintosh/i.test(userAgent) && 'ontouchend' in document; // Detect iPads
    const isAndroidTablet = /Android(?!.*Mobile)/i.test(userAgent); // Detect Android tablets

    if (isMobile || isAppleTablet || isAndroidTablet) {
        return false
    } else {
        return true
    }



}