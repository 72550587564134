import { useEffect } from "react"
import { postLogDetails } from "../../../Services/APIs/appDetails"

const FrontCameraError = ({ back }) => {

    useEffect(()=>{
        postLogDetails({
            uid: JSON.parse(localStorage.getItem("usertpi")).usertpi,
            app: JSON.parse(localStorage.getItem("companyBucket")).companyBucket,
            payLoad : "selfie camera not found"
        })

    }, [])
    
    return (
        <div className="h-full z-20 absolute w-full top-0 bg-black/[0.3] backdrop-blur-sm flex items-center justify-center">
            <div className="bg-white w-11/12 rounded-md p-4">
                <h1 className=" text-xl font-bold">Camera access issue</h1>

                <p className="ms-2 mt-3"><span className="me-1"> &#8226;</span> We couldn't access your front camera.</p>
                <p className="ms-2"><span className="me-1"> &#8226;</span>Your back camera is currently active.</p>

                <p className="mt-3">Would you like to retry with selfie?</p>
                <div className="flex mt-4">
                    <button
                        type="button"
                        className="w-full bg-black text-white py-2 me-3 rounded"
                        onClick={() => { back(false) }}>Continue</button>
                    <button
                        type="button"
                        className="w-full bg-black text-white py-2 rounded"
                        onClick={() => window.location.reload()}>
                        Retry Selfie
                    </button>
                </div>

            </div>
        </div>
    )
}

export default FrontCameraError