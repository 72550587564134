import { Navigate } from "react-router-dom"

const Protect = () => {

    const hostName = window.location.hostname
    const parts =  hostName.split(".")
    const isPepsodent = parts[0] === "pepsodent"
    console.log(parts)

    if (isPepsodent) {
        return <Navigate to="/unilever" />
    } else {
        return <Navigate to="/selfie" />
    }


}

export default Protect