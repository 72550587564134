import React, { useEffect } from "react"
import { postLogDetails } from "../../../Services/APIs/appDetails"

const PermissionPopup = (props) => {

    useEffect(()=>{
        postLogDetails({
            uid: JSON.parse(localStorage.getItem("usertpi")).usertpi,
            app: JSON.parse(localStorage.getItem("companyBucket")).companyBucket,
            payLoad: "camera permission denied"
        })        
    }, [])

    return (
        <div className="absolute top-0 camera-error-popup bg-black/[0.3] backdrop-blur-sm h-full w-full flex justify-center items-center px-6">
            <div className=" flex flex-col bg-white p-5 rounded md:w-2/3">
                <p className=" text-2xl font-bold text-center py-3 md:text-3xl">INFORMASI</p>
                <p className="pb-5 md:text-lg">   Tampaknya Anda menonaktifkan izin kamera. Geser layar ke bawah dan terima izin kamera untuk melanjutkan. Anda juga bisa pergi ke pengaturan dan mengaktifkan izin kamera</p>
                <p className=" font-normal text-sm text-black md:text-lg">
                    <span className=""> Butuh bantuan? Kunjungi</span>
                    <span className="font-bold pl-2 md:text-lg">
                        <a href="mailto:support@toothlens.com"> Pusat Bantuan </a>
                    </span>
                </p>
                <button
                    type="button"
                    onClick={() => window.history.back()}
                    className="bg-black text-white px-5 h-11 font-semibold text-base rounded self-end md:text-xl md:h-14">Oke</button>
            </div>
        </div>
    )
}

export default PermissionPopup