import { Route, Routes } from "react-router-dom"
import LandingPage from "./UnileverLandingPage/landingPage"
import LookingFor from "./UnileverLookingFor/lookingFor"
import FullScan from "./UnileverScan/fullScan"
import ThankYou from "./UnileverThanks/thanks"
import ReadPhNo from "./ReadPhNo/readPhNo"
import PageNotFound from "../PageNotFound"
import SingleScan from "./UnileverScan/singleScan"
import IPadThanks from "./UnileverThanks/ipad-thanks"

const Unilever = () => {
    return (
        <div className="h-full">
            <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="looking-for" element={<LookingFor />} />
                <Route path="scan" element={<FullScan />} />
                <Route path="single-scan" element={<SingleScan />} />
                <Route path="thanks" element={<ThankYou />} />
                <Route path="i-thanks" element={<IPadThanks />} />
                <Route path=":ph_no" element={<ReadPhNo />} />
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </div>
    )
}

export default Unilever