import axios from "axios";
import { refreshToken } from "./authentication";
import { dentalOfficeName } from "../../Utils/AppDetails/appDetails";
export let headers = {
    "Content-Type": "application/json",
    hosts: window.location.origin,
    dentalOfficeName
}

const client = axios.create({
    headers: {
        "Content-Type": "application/json",
        hosts: window.location.origin,
    },
})


client.interceptors.request.use(
    async (config) => {
        const authToken = localStorage.getItem("authToken");
        let currentDate = new Date();
        const decodedToken = authToken ? JSON.parse(atob(authToken.split('.')[1])) : '';
        if (decodedToken?.exp * 1000 < currentDate.getTime()) {
            const userDetails = JSON.parse(localStorage.getItem('user'));
            const tokenRefresh = userDetails?.token;
            const email = userDetails.user.email;
            const responseData = await refreshToken(tokenRefresh, email);
            config.headers["Authorization"] = responseData.data.data.jwtToken;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);


client.interceptors.response.use(
    (response) => { return response },
    (error) => {
        if (error && error?.response?.status === 502) {
            // localStorage.setItem("token expired", JSON.stringify(true))
        }
        return Promise.reject(error)
    }
)


export default client