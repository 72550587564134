import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getBinaryData } from "../Services/APIs/profile"

const initialState = {
    imageUrl: '',
    isProfileExist: true,
    isProfileLoading: true,
}

export const getProfileLogo = createAsyncThunk('profileLogo/getProfileLogo', async (obj) => {
    return await getBinaryData(obj)
})

const profileLogoSlice = createSlice({
    name: "profileLogo",
    initialState,
    reducers: {
        clearProfile: (state) => {
            return initialState
        },

        noProfile: (state) => {
            state.isProfileLoading = false
            state.isProfileExist = false
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProfileLogo.pending, (state) => {
                state.isProfileLoading = true
            })
            .addCase(getProfileLogo.fulfilled, (state, action) => {
                state.isProfileLoading = false
                state.isProfileExist = true
                state.imageUrl = action.payload // Assuming response is the image URL 
            })
            .addCase(getProfileLogo.rejected, (state, action) => {
                state.isProfileLoading = false
                state.imageUrl = ''
                state.isProfileExist = false
            })
    }
})

export default profileLogoSlice.reducer
export const { clearProfile, noProfile } = profileLogoSlice.actions