import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import welcomeTeeth from "../../../assets/illustrations/welcomeTeeth.png";
import toothlensLogo from "../../../assets/illustrations/Toothlens_logo.png";
import pepsodent_logo from "../../../assets/unilever/Pepsodent Logo.png"
import { generateRandomAlphaNumeric } from "../../../Utils/alphaNumeric";
import { postLogDetails } from "../../../Services/APIs/appDetails";



const UnileverIpad = () => {

    const navigate = useNavigate()
    const storedUid = localStorage.getItem("usertpi")
    const initialId = storedUid ? JSON.parse(storedUid).usertpi : ''
    const [uid, setUid] = useState(initialId)
    const { images } = useSelector((state) => state.fullScan);

    localStorage.setItem("companyBucket", JSON.stringify({ companyBucket: "unilever" }));
    localStorage.setItem("isAutomated", true)
    localStorage.setItem("isUnileverIpad", true)

    useEffect(() => {
        !localStorage.getItem("reloaded") && reload()
        if (!uid || images.length === 0) {
            generateInfo()
        }

    }, [])

    const reload = () => {
        localStorage.setItem("reloaded", true)
        window.location.reload()
    }

    const generateInfo = () => {
        const randomString = generateRandomAlphaNumeric(2);
        const timestamp = Date.now().toString();
        const randomString2 = generateRandomAlphaNumeric(1)
        const unique = randomString + timestamp + randomString2
        localStorage.setItem("usertpi", JSON.stringify({ usertpi: unique }));
        localStorage.setItem("companyBucket", JSON.stringify({ companyBucket: "unilever" }));
        postLogDetails({ uid, app: "unilever", payLoad: "started ipad flow" })
        setUid(unique)
    }

    const openIssues = () => {
        navigate("/unilever/looking-for")
    }

    const newScan = () => {
        localStorage.clear()
        localStorage.setItem("isAutomated", true)
        localStorage.setItem("reloaded", true)
        localStorage.setItem("isUnileverIpad", true)
        generateInfo()
        openIssues()
    }


    return (
        <div className="h-full">
            <div className="h-1/2 flex flex-col items-center justify-center">
                <img
                    src={welcomeTeeth}
                    alt="welcome teeth"
                    className="saturation h-2/5"
                />
                <img
                    src={pepsodent_logo}
                    alt="logo"
                    className=" w-4/5"
                />
            </div>
            <div className="h-1/2 relative flex flex-col justify-center">
                <h5 className=" text-2xl  font-medium text-center mb-3 md:text-5xl md:mb-7">
                    Analisa awal kesehatan gigi
                </h5>
                <div className="text-lg font-medium text-center text-black opacity-60 mb-3 mx-8 md:text-2xl md:mb-10" >
                    Ambil 3 foto untuk mendapatkan analisa awal kesehatan gigi dengan teknologi AI
                </div>

                <div>
                    <div className="h-48  flex flex-col items-center px-4 py-2">
                        {

                            localStorage.getItem("isScanCompleted") ?
                                <div className="flex flex-col items-center">
                                    <p className=" text-xl text-green-600 font-medium text-center my-2 md:text-3xl md:mb-5">Anda telah berhasil menyelesaikan pemindaian Anda</p>
                                    <button
                                        type="button"
                                        className=" bg-red-unilever rounded text-white w-full h-11 font-semibold text-base mb-4 md:w-2/3 md:h-16  md:text-2xl"
                                        onClick={newScan}
                                    >
                                        Ambil Pemindaian Baru
                                    </button>
                                </div>
                                :

                                <button
                                    type="button"
                                    className=" bg-red-unilever rounded text-white w-full h-11 font-semibold text-base mb-4 md:w-1/2 md:text-xl"
                                    onClick={openIssues}
                                >
                                    Mulai
                                </button>
                        }

                    </div>
                </div>

                <div className="absolute bottom-5 w-full px-4">

                    <p className="flex items-center justify-center md:text-xl">
                        <span className="opacity-60"> Powered by </span>

                        <img
                            src={toothlensLogo}
                            alt="welcome teeth"
                            className=" ml-2 w-24 md:w-32"
                        />
                    </p>

                </div>
            </div>
        </div>
    )
}

export default UnileverIpad