import { useEffect, useState } from "react"
import Shoot from "../Scan/shoot"
import ToothSelector from "../Scan/toothSelector"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { postLogDetails } from "../../Services/APIs/appDetails"
const FScan = () => {
    const navigate = useNavigate()
    const { images } = useSelector((state) => state.fullScan);
    const [activeImageSide, setImageSide] = useState(images.length < 5 ? images.length : 0)
    const [isToothPicker, setToothPicker] = useState(false)

    useEffect(() => {
        if (!localStorage.getItem("usertpi") || localStorage.getItem("isScanCompleted")) {
            navigate("/selfie")
        }
        if (images.length >= 5) {
            setToothPicker(true)
        }

    }, [activeImageSide])




    return (
        <div className="h-full">
            {
                isToothPicker ?
                    <ToothSelector setImageSide={setImageSide} setToothPicker={setToothPicker} /> :
                    <Shoot activeImageSide={activeImageSide} setToothPicker={setToothPicker} quickScan={false} setImageSide={setImageSide} />
            }
        </div>
    )
}

export default FScan