import React from 'react';
import { Route, Routes } from 'react-router-dom';
import OnBoarding from '../Onboarding';
import TpScanLanding from '../Landing/tpscanLanding';
import PageNotFound from '../PageNotFound';
import FullScan from '../Scan/fullScan';

const TPScanSelfie = () => {

    return (
        <div className="h-full">
            <Routes>
                <Route path="onboarding" element={<OnBoarding />} />
                <Route path="full-scan" element={<FullScan />} />
                <Route path=":uid" element={<TpScanLanding />} />
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </div>
    )
}

export default TPScanSelfie