import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import welcomeTeeth from "../../../assets/illustrations/welcomeTeeth.png";
import "../../../App.css";
import toothlensLogo from "../../../assets/illustrations/Toothlens_logo.png";
import { useDispatch, useSelector } from "react-redux";
import { createTpScanS3Folder } from "../../../Store/tpScan";
import { getTpStatus } from "../../../Services/APIs/scan";
import successImage from "../../../assets/onboarding-images/success.png";
// import { tpscanName } from "../../../Utils/AppDetails/appDetails";
import { getTpScanAppDetails, postLogDetails } from "../../../Services/APIs/appDetails";
import { generateRandomAlphaNumeric } from "../../../Utils/alphaNumeric";
 
const TpScanLanding = () => {
  const [scan, setScan] = useState(true);
  const [error, setError] = useState();
  const [message, setMessage] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [uiLoading, setUiLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const { tpscanName, uid } = useParams();
  const companyBucket=tpscanName
  localStorage.setItem(
    "companyBucket",
    JSON.stringify({ companyBucket:"pg" })
  );
  localStorage.removeItem("isAutomated")
  const takeScan = () => {
      const randomString = generateRandomAlphaNumeric(2);
      const timestamp = Date.now().toString();
      const randomString2 = generateRandomAlphaNumeric(1)
      const unique = randomString + timestamp + randomString2
      localStorage.setItem("usertpi", JSON.stringify({ usertpi: unique }));
      postLogDetails({uid:unique, app: "pg", payLoad : "new id created"})
    navigate(`/pg/scan`);
  };

 
  


  useEffect(() => {
    localStorage.setItem("reload", true)
    postLogDetails({ app: "pg", payLoad : "visited landing page"})

  }, []);
  return (
    <div className="h-full">
      <div className="h-1/2">
        <img
          src={welcomeTeeth}
          alt="welcome teeth"
          className="saturation h-full w-full object-contain"
        />
      </div>
      
        <div className="h-1/2 relative flex flex-col justify-center">
          <h5
            className=" text-2xl  font-semibold text-center mb-3"
          >
            デンタルスキャン       </h5>
          <div
            className="font-semibold text-center text-black opacity-60 mb-3"
          >
      写真から歯の状態をAIがチェック
          </div>
          <div>
            <div className="h-48 mt-2   flex flex-col items-center px-4 py-2">
                <button 
                  type="button"
                  style={{backgroundColor:"#1E1E78"}}
                  className="  w-2/3 rounded-full border-2 text-white py-2 px-4 shadow-2xl  font-bold "
                  onClick={() => {
                    takeScan();
                  }}
                  disabled={loading}
                >
                  {loading ? (
                    <div className="loader-container">
                      <div className="loader dark-loader"></div>
                    </div>
                  ) : (
                    <span > スキャン開始 </span>
                  )}
                </button>
            
            </div>
          </div>

          <div className="absolute bottom-11 w-full px-4">
           
              <p className="flex flex-row justify-center">
                <span className="opacity-60"> Powered by </span>

                <img
                  src={toothlensLogo}
                  alt="welcome teeth"
                  className="h-6 ml-2 w-24"
                />
              </p>
          </div>
        </div>
    </div>
  );
};

export default TpScanLanding;
